import BotIcon from "virtual:icons/lucide/bot";
import ChevronLeft from "virtual:icons/lucide/chevron-left";
import ChevronRight from "virtual:icons/lucide/chevron-right";
import CircleDollarSignIcon from "virtual:icons/lucide/circle-dollar-sign";
import CoinsIcon from "virtual:icons/lucide/coins";
import Globe2Icon from "virtual:icons/lucide/globe-2";
import HeartIcon from "virtual:icons/lucide/heart";
import HomeIcon from "virtual:icons/lucide/home";
import LibraryIcon from "virtual:icons/lucide/library";
import MessagesSquareIcon from "virtual:icons/lucide/messages-square";
import UserPlusIcon from "virtual:icons/lucide/user-plus";
import UserRoundIcon from "virtual:icons/lucide/user-round";
import { SignInButton, SignUpButton, SignedIn, SignedOut, useUser } from "@clerk/remix";
import { Link, type NavLinkProps, NavLink as RemixNavLink } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { $path } from "remix-routes";
import slugify from "slugify";
import { useLocalStorage } from "usehooks-ts";

import { useMediaQuery } from "#app/hooks/use-media-query";
import { cx } from "#app/utils/cva.ts";
import { Button } from "../button";
import { LanguageSwitcher } from "../language-switcher";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { Separator } from "../separator";
import { Sheet, SheetContent } from "../sheet";
import { categories } from "../tag-form";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";

export function Sidebar({
	isOpen,
	setOpen,
}: {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isOpen: boolean;
}) {
	const isDesktop = useMediaQuery("(min-width: 1024px)");

	if (!isDesktop) {
		return (
			<Sheet onOpenChange={setOpen} open={isOpen}>
				<SheetContent side="left">
					<SidebarContent onOpenChange={setOpen} />
				</SheetContent>
			</Sheet>
		);
	}

	return <SidebarContent />;
}

function SidebarContent({
	onOpenChange,
}: {
	onOpenChange?: (value: boolean) => void;
}) {
	const [isCollapsed, setCollapsed] = useLocalStorage("__sidebar_desktop_state", false);
	const isDesktop = useMediaQuery("(min-width: 1024px)");
	const { user } = useUser();
	const { t } = useTranslation("common");

	const isOpen = isDesktop ? !isCollapsed : true;

	const guestLinks = [
		{
			to: "/search",
			Icon: Globe2Icon,
			label: t("discover"),
		},
	];

	const authLinks = [
		{
			to: "/recent-chats",
			Icon: MessagesSquareIcon,
			label: t("myChats"),
		},
		{
			to: "/my-characters",
			Icon: BotIcon,
			label: t("myCharacters"),
		},
		{
			to: "/favorite-characters",
			Icon: HeartIcon,
			label: t("favorites"),
		},
	];

	return (
		<div
			className={cx(
				"relative max-h-full min-h-full bg-side-bar py-3 transition-transform duration-300 dark:border-zinc-700 dark:bg-[#202035]",
				isOpen ? "lg:w-[16.5rem]" : "lg:w-[72px]",
				isDesktop
					? "hidden lg:z-50 lg:flex lg:shrink-0 lg:flex-col lg:border-r"
					: "flex shrink-0 flex-col border-border border-r lg:hidden",
			)}
		>
			<SidebarToggle isCollapsed={isCollapsed} onOpenChange={onOpenChange} setCollapsed={setCollapsed} />

			{/* navigation */}
			<div className="-mr-1.5 flex-1 grow overflow-y-scroll">
				<div className="p-2 pt-1">
					<ul className={cx(!isOpen && "flex flex-col items-center", !user?.id && "flex flex-col gap-2")}>
						<SignedOut>
							{isOpen ? (
								<>
									<li onClick={() => onOpenChange?.(false)}>
										<SignInButton>
											<Button className="w-full">
												<span>{t("signIn")}</span>
											</Button>
										</SignInButton>
									</li>

									<li onClick={() => onOpenChange?.(false)}>
										<SignUpButton>
											<Button $variant="outline" className="w-full">
												<span>{t("createAnAccount")}</span>
											</Button>
										</SignUpButton>
									</li>
								</>
							) : null}

							{guestLinks.map((link) => (
								<li key={link.to} onClick={() => onOpenChange?.(false)}>
									<NavlinkWithTooltip {...link} isOpen={isOpen} />
								</li>
							))}
							<li onClick={() => onOpenChange?.(false)}>
								<NavlinkWithTooltip
									Icon={CircleDollarSignIcon}
									className="lg:hidden"
									isOpen={isOpen}
									label={t("market")}
									to={$path("/market")}
								/>
							</li>
						</SignedOut>

						<SignedIn>
							<li onClick={() => onOpenChange?.(false)} className="lg:hidden">
								<NavlinkWithTooltip Icon={HomeIcon} isOpen={isOpen} label="Home" to="/" />
							</li>
						</SignedIn>
					</ul>

					<SignedIn>
						<Separator className="lg:hidden" />

						<ul className={cx(!isOpen && "flex flex-col items-center")}>
							<li onClick={() => onOpenChange?.(false)}>
								<NavlinkWithTooltip
									Icon={UserRoundIcon}
									isOpen={isOpen}
									label={t("myProfile")}
									to={`/creators/${user?.username}`}
								/>
							</li>
							{authLinks.map((link) => (
								<li onClick={() => onOpenChange?.(false)} key={link.to}>
									<NavlinkWithTooltip {...link} isOpen={isOpen} />
								</li>
							))}
							<li onClick={() => onOpenChange?.(false)}>
								<NavlinkWithTooltip
									Icon={CircleDollarSignIcon}
									className="lg:hidden"
									isOpen={isOpen}
									label={t("market")}
									to={$path("/market")}
								/>
							</li>
							<li onClick={() => onOpenChange?.(false)}>
								<NavlinkWithTooltip
									Icon={CoinsIcon}
									className="lg:hidden"
									isOpen={isOpen}
									label="Tokens"
									to={$path("/tokens")}
								/>
							</li>
						</ul>
					</SignedIn>

					<Separator />

					<h3 className={cx("px-4 py-2 text-center font-semibold text-base", !isOpen && "sr-only")}>
						{t("ourAICategories")}
					</h3>

					{isOpen ? (
						<ul>
							{categories.map((category) => (
								<li onClick={() => onOpenChange?.(false)} key={category}>
									<NavLink
										className="rounded-md text-gray-600 dark:text-zinc-300"
										end
										to={$path("/categories/:categorySlug", {
											categorySlug: slugify(category, {
												lower: true,
											}),
										})}
									>
										{t(category)}
									</NavLink>
								</li>
							))}
						</ul>
					) : (
						<div className="flex w-full justify-center">
							<Popover>
								<PopoverTrigger className={cx(navLinkClasses, "mx-auto w-auto")}>
									<LibraryIcon />
								</PopoverTrigger>

								<PopoverContent
									className="flex h-[60vh] min-h-[300px] w-full flex-col overflow-y-auto rounded-lg p-1.5 py-4 font-semibold text-xs dark:bg-[#202035]"
									side="right"
								>
									<h3 className="px-4 pb-2 text-center font-semibold text-gray-600 text-lg dark:text-zinc-300">
										{t("ourAICategories")}
									</h3>

									<ul>
										{categories.map((category) => (
											<li key={category}>
												<NavLink
													className="rounded-md text-gray-600 dark:text-zinc-300"
													end
													to={$path("/categories/:categorySlug", {
														categorySlug: slugify(category, {
															lower: true,
														}),
													})}
												>
													{t(category)}
												</NavLink>
											</li>
										))}
									</ul>
								</PopoverContent>
							</Popover>
						</div>
					)}
				</div>
			</div>

			<div className="mt-auto flex flex-col gap-2 px-2 lg:hidden">
				<Button asChild onClick={() => onOpenChange?.(false)}>
					<Link to={$path("/characters/create")}>
						<UserPlusIcon />
						{t("createCharacter")}
					</Link>
				</Button>
				<LanguageSwitcher className="w-full" />
			</div>
		</div>
	);
}

function NavlinkWithTooltip({
	isOpen,
	to,
	Icon,
	label,
	className,
}: {
	isOpen: boolean;
	to: string;
	Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
	label: string;
	className?: string;
}) {
	if (!isOpen) {
		return (
			<Tooltip>
				<TooltipTrigger>
					<NavLink className={cx("flex items-center gap-2", className)} end to={to}>
						<Icon className="shrink-0" />
						<span className="sr-only">{label}</span>
					</NavLink>
				</TooltipTrigger>

				<TooltipContent side="right" sideOffset={16}>
					<span>{label}</span>
				</TooltipContent>
			</Tooltip>
		);
	}

	return (
		<NavLink className={cx(!isOpen && "justify-center", className)} end to={to}>
			<div className={cx("flex items-center gap-2", !isOpen && "justify-center")}>
				<Icon className="shrink-0" />
				<span className={cx(!isOpen && "sr-only")}>{label}</span>
			</div>
		</NavLink>
	);
}

const navLinkClasses = cx(
	"inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg border-0 bg-transparent px-4 py-2 font-normal text-sm ring-offset-background transition-transform disabled:pointer-events-none aria-current-page:bg-primary dark:aria-current-page:bg-primary dark:hover:bg-primary/90 hover:bg-primary/10 aria-current-page:text-white dark:aria-current-page:text-white hover:text-accent-foreground disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
);

function NavLink({ className, ...props }: NavLinkProps) {
	return <RemixNavLink {...props} className={cx(navLinkClasses, className)} />;
}

function SidebarToggle({
	isCollapsed,
	setCollapsed,
	onOpenChange,
}: {
	isCollapsed: boolean;
	setCollapsed: (value: boolean) => void;
	onOpenChange?: (value: boolean) => void;
}) {
	const isDesktop = useMediaQuery("(min-width: 1024px)");
	const { t } = useTranslation("common");
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						$size="icon"
						$variant="translucent"
						className="-right-4 -translate-y-1/2 absolute top-1/2 size-8 rounded-full p-1.5 px-1 transition-transform"
						onClick={() => (isDesktop ? setCollapsed(!isCollapsed) : onOpenChange?.(false))}
						type="button"
					>
						<ChevronLeft className={cx(isCollapsed ? `${isDesktop ? "hidden" : "block"}` : "block")} />
						{isDesktop && <ChevronRight className={cx(!isCollapsed && "hidden")} />}
					</Button>
				</TooltipTrigger>

				<TooltipContent side={!isCollapsed ? "bottom" : "right"} sideOffset={8}>
					{isDesktop ? (
						<span>
							{!isCollapsed ? "Collapse" : "Expand"} {t("sidebar")}
						</span>
					) : (
						<span> {t("collapseSidebar")}</span>
					)}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
}
